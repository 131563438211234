/**
 * ID: bh-base-mobile-theme-scss
 * Name: base.mobile.scss
 * Description: Mobile-specific variants of base stylesheet for Baystate Health themes
 * Version: 4
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Adjust cta-button style
 * 2021-07-27 - MW - v3: Added missing alert danger class
 * 2022-05-25 - MW - v4: Split global.scss into base.scss, base.mobile.scss, canned.scss
 */
 /*
 * Base Mobile CSS
 * ----------------------------------------------------------------------------
 * Base theming variants for narrow viewports (mobile).
 */

 @media (max-width: 767px) {
  h1 {
    font-size: 2rem;
  }

  .side-tabs-content-container {
    position: relative;

    .side-tabs-option-pane {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .content-stage-header {
    flex-direction: column-reverse;

    .segment-toolbar {
      margin-bottom: 16px;

      .search-bar-wrapper {
        max-width: none;
      }
    }
  }

  .footer-spacer {
    height: 275px;
  }

  ion-list.list-md:not(.no-indent) {
    margin-left: 0;
  }

  .desktop-only,
  .desktop-only-flex,
  .desktop-only-inline {
    display: none !important;
  }

  .mobile-only {
    display: block !important;
  }

}
